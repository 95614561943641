@import 'common/common.scss';

.logo-nav{
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    
}

.site-nav{
    padding: $comman-padding;
    max-width: $mw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .auth-link{
        font-weight: 600;
        padding: .5rem .75rem;
        // background-color: rgb(237, 237, 237);
        background-color: transparent;
        color: white;
        border: 1px solid rgb(205, 205, 205);
        border-radius: 10px;
        transition: .3s ease;

        &:nth-of-type(2){
            background-color: black;
            color: white;
            border: 1px solid black;
            margin-left: .75rem;
        }

        &:hover{
            border-color: white;
        }
    }
}