a{
    text-decoration: none;
    color: white;

}

.sighting-element{
    padding: 1rem;
    background-color: #171e28;
    margin-bottom: 1rem;
    border-radius: 15px;

    header h2{
        margin-top: 0;
    }

    .sighting-description{
        color: #a9a9a9;
    }
    .sighting-date{
        span{
            color: #a9a9a9;
        }
        text-align: right;
        font-size: .7rem;
    }
}